.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5%;
}
.title {
  background-color: #eeeaea;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 36px;
  line-height: 56px;
}

.query-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tables {
  margin-top: 5% !important;
  margin-left: 10% !important;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.tables .query-table {
  margin-left: 2%;
  margin-bottom: 2%;
  width: 40% !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.tables .query-table:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.query-table caption { 
  text-align: center !important;
}