.query-table {
  max-height: 50vh;
  overflow: auto;
  width: 100%;
}
table {
  width: 100%;
}
caption {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
}
.query-table td {
  padding: 20px 5px;
}
.selected {
  background-color: rgb(99, 0, 0) !important;
  color: #fff;
}
/* .query-table tr:hover {
  cursor: pointer;
  background-color: rgb(255, 204, 204) !important;
} */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.img-thumbnail { 
  background-color: #bbb;
  width: 72px;
  height: auto;
}