.orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 5%; */
}

.title {
  background-color: #eeeaea;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 36px;
  line-height: 56px;
}

.content {
  width: 95%;
  display: flex;
  justify-content: space-around;
}
.left {
  flex: 1;
  /* background-color: teal; */
  display: flex;

  flex-direction: column;
}

.left p {
  font-size: 24px;
  margin-top: 2%;
  /* margin-block-start: 0; */
  /* margin-block-end: 0; */
}
.right {
  flex: 1;
  /* width: 45%; */
  /* border-left: 1px solid black; */

  /* background-color: tomato; */
}
.center {
  flex: 1;
}

.preview-icon {
  width: 128px;
  margin: 0 auto;
  background-color: #bbb;
}
/* 
.single-col {
  display: flex;
  flex-direction: column; */
/* } */

input[type="text"],
input[type="datetime-local"],
input[type="file"],
input[type="number"],
select,
button,
textarea {
  width: 40%;
  padding: 12px 20px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="file"] {
  width: 85%;
}
.single-col input[type="text"],
input[type="datetime-local"],
select,
textarea {
  width: 80%;
  resize: none;
}
.images-found {
  display: flex;
  justify-content: space-around;
}
.images-found img {
  height: 10%;
  width: 20%;
}
.centered {
  justify-content: center;
  align-items: center;
}
.gblcbk {
  background: url("https://cdn.discordapp.com/attachments/843885450850336799/843886872556077056/1-removebg-preview_1.png")
    0% 0% / contain no-repeat;
}
.rc-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
  display: flex;
  margin-top: 2%;
  margin-bottom: -57px;
}
.rc-steps,
.rc-steps * {
  box-sizing: border-box;
}
.rc-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  overflow: hidden;
}
.rc-steps-item-container[role="button"] {
  cursor: pointer;
  transition: opacity 0.3s;
}
.rc-steps-item-container[role="button"]:hover {
  opacity: 0.7;
}
.rc-steps-item:last-child {
  flex: none;
}
.rc-steps-item:last-child .rc-steps-item-tail,
.rc-steps-item:last-child .rc-steps-item-title:after {
  display: none;
}
.rc-steps-item-container {
  display: inline-block;
}
.rc-steps-item-icon,
.rc-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.rc-steps-item-icon {
  border: 1px solid #ccc;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 34px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}
.rc-steps-item-icon > .rc-steps-icon {
  line-height: 1;
  top: -1px;
  color: #ff0404;
  position: relative;
}
.rc-steps-item-icon > .rc-steps-icon.rcicon {
  font-size: 12px;
  position: relative;
  top: -2px;
}
.rc-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}
.rc-steps-item-tail:after {
  content: "";
  display: inline-block;
  background: #e9e9e9;
  height: 1px;
  border-radius: 1px;
  width: 100%;
  transition: background 0.3s;
}
.rc-steps-item-content {
  margin-top: 6px;
}
.rc-steps-item-title {
  font-size: 14px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
  position: relative;
}
.rc-steps-item-title:after {
  content: "";
  height: 1px;
  width: 1000px;
  background: #e9e9e9;
  display: block;
  position: absolute;
  top: 0.55em;
  left: 100%;
}
.rc-steps-item-subtitle {
  font-size: 12px;
  display: inline-block;
  color: #999;
  margin-left: 8px;
}
.rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-item-wait .rc-steps-item-icon {
  border-color: #ccc;
  background-color: #fff;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: #ccc;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ccc;
}
.rc-steps-item-wait .rc-steps-item-title {
  color: rgba(0, 0, 0, 0.43);
}
.rc-steps-item-wait .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-wait .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.43);
}
.rc-steps-item-wait .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-icon {
  border-color: #ff0404;
  background-color: #fff;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #ff0404;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ff0404;
}
.rc-steps-item-process .rc-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.rc-steps-item-process .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.rc-steps-item-process .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item-process .rc-steps-item-icon {
  background: #ff0404;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon {
  /* border-color: #FF0404; */
  background-color: #ff0404;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: #ff0404;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ff0404;
}
.rc-steps-item-finish .rc-steps-item-title {
  color: rgba(0, 0, 0, 0.43);
}
.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #ff0404;
}
.rc-steps-item-finish .rc-steps-item-description {
  color: rgba(0, 0, 0, 0.43);
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #ff0404;
}
.rc-steps-item-error .rc-steps-item-icon {
  border-color: #f50;
  background-color: #fff;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #f50;
}
.rc-steps-item-error .rc-steps-item-title {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-title:after {
  background-color: #e9e9e9;
}
.rc-steps-item-error .rc-steps-item-description {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}
.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
  background: #f50;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
  margin-right: 10px;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
  margin-right: 0;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
  display: none;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
  max-width: 100px;
}
.rc-steps-item-custom .rc-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: 1px;
  width: 20px;
  height: 20px;
}
.rc-steps-item-custom.rc-steps-item-process
  .rc-steps-item-icon
  > .rc-steps-icon {
  color: #ff0404;
}
.rc-steps-small .rc-steps-item-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 18px;
  font-size: 12px;
  margin-right: 10px;
}
.rc-steps-small .rc-steps-item-icon > .rc-steps-icon {
  font-size: 12px;
  font-size: 9px \9;
  transform: scale(0.75);
  top: -1px;
}
.rc-steps-small .rc-steps-item-content {
  margin-top: 0;
}
.rc-steps-small .rc-steps-item-title {
  font-size: 12px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
}
.rc-steps-small .rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-small .rc-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
.rc-steps-small .rc-steps-item-tail:after {
  height: 1px;
  border-radius: 1px;
  width: 100%;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  border-radius: 0;
  border: 0;
  background: none;
}
.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: -2.5px;
  transform: none;
}
.rc-steps-vertical {
  display: block;
}
.rc-steps-vertical .rc-steps-item {
  display: block;
  overflow: visible;
}
.rc-steps-vertical .rc-steps-item-icon {
  float: left;
}
.rc-steps-vertical .rc-steps-item-icon-inner {
  margin-right: 16px;
}
.rc-steps-vertical .rc-steps-item-content {
  min-height: 48px;
  overflow: hidden;
  display: block;
}
.rc-steps-vertical .rc-steps-item-title {
  line-height: 34px;
}
.rc-steps-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-vertical .rc-steps-item-description {
  padding-bottom: 12px;
}
.rc-steps-vertical .rc-steps-item-tail {
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 30px 0 4px 0;
}
.rc-steps-vertical .rc-steps-item-tail:after {
  height: 100%;
  width: 1px;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-tail {
  position: absolute;
  left: 9px;
  top: 0;
  padding: 22px 0 4px 0;
}
.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
  line-height: 18px;
}
.rc-steps-label-vertical .rc-steps-item {
  overflow: visible;
}
.rc-steps-label-vertical .rc-steps-item-tail {
  padding: 0px 24px;
  margin-left: 48px;
}
.rc-steps-label-vertical .rc-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 100px;
}
.rc-steps-label-vertical .rc-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.rc-steps-label-vertical .rc-steps-item-title {
  padding-right: 0;
}
.rc-steps-label-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-label-vertical .rc-steps-item-description {
  text-align: left;
}
.rc-steps-dot .rc-steps-item-tail {
  width: 100%;
  top: 1px;
  margin: 0 0 0 50px;
  padding: 0;
}
.rc-steps-dot .rc-steps-item-tail:after {
  height: 3px;
}
.rc-steps-dot .rc-steps-item-icon {
  padding-right: 0;
  width: 5px;
  height: 5px;
  line-height: 5px;
  border: 0;
  margin-left: 48px;
}
.rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
}
.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon {
  top: -1px;
  width: 7px;
  height: 7px;
  line-height: 7px;
}
.rc-steps-dot
  .rc-steps-item-process
  .rc-steps-dot
  .rc-steps-item-icon
  .rc-steps-icon-dot {
  border-radius: 3.5px;
}
.rc-steps-navigation {
  padding-top: 8px;
}
.rc-steps-navigation.rc-steps-horizontal .rc-steps-item-description {
  max-width: 140px;
}
.rc-steps-navigation .rc-steps-item {
  box-sizing: border-box;
  text-align: center;
  overflow: visible;
}
.rc-steps-navigation .rc-steps-item-container {
  text-align: left;
  padding-bottom: 8px;
  outline: none;
}
.rc-steps-navigation .rc-steps-item-title {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rc-steps-navigation .rc-steps-item-title:after {
  display: none;
}
.rc-steps-navigation .rc-steps-item:last-child {
  flex: 1;
}
.rc-steps-navigation .rc-steps-item:last-child:after {
  display: none;
}
.rc-steps-navigation .rc-steps-item:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  margin-left: -8px;
}
.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  padding-bottom: 5px;
  border-bottom: 3px solid #ff0404;
}
.rcicon-check {
  background-color: black;
}
/* .rcicon:before {
  display: block;
  font-family: "anticon" !important;
} */

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 500px;
}
.list img {
  border: 1px solid rgba(0, 0, 0, 0.43);
  margin: 10px;
  width: 128px;
}
.flip-garment {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.garment-container {
  height: 720px;
  padding: 0;
  position: relative;
  width: 900px;
  background-size: auto 720px !important;
}
.garment-container-mini {
  height: 400px;
  width: 500px;
  background-size: auto 400px !important;
}
.garment-container img {
  /* height: auto !important; */
}
.rc-steps-item-container {
  cursor: pointer;
}
