.sidebar {
  flex: 1;
  background-color: #454444;
  color: #fff;
  height: 100%;
  text-align: center;
}
.sidebar img {
  margin-top: 15px;
  width: 128px;
}
.sidebar ul {
  list-style-type: none;
  text-align: left;
  padding-inline-start: 0;
}
li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}
li a {
  font-size: 18px;
  padding: 15px;
  color: inherit;
  text-decoration: inherit;
}
.active {
  color: #ed1c24;
  font-weight: bolder;
  background-color: #353535;
}
.nav-tree label { 
  font-size: 12px;
}

.nav-tree select { 
  height: 24px;
  padding: 0;
  font-size: 14px;
  color: #454444;
}
.garment-actions { 
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: space-around;

  flex-wrap: wrap;
}
.garment-actions button { 
  padding: 2px;
  font-size: 12px;
  /* width: 40%; */
  /* margin: ; */
  cursor: pointer;
}
.garment-actions button:hover{
  background: #353535 !important;
  color: #fff !important;
}
.reseller-dropdown {  
    padding: 10px 5px !important;
    width: 70%; 
    height: unset !important;
    color: #fff !important;
    background-color: rgba(239, 239, 239, 0.3) !important;
    
}
.reseller-dropdown option { 
  background-color: rgba(239, 239, 239, 0.3) !important;
  color: #000 !important;
}