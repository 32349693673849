.query-table {
  /* max-height: unset !important; */
}
tr:hover {
  cursor: inherit;
  background-color: #cccc !important;
}

.completed {
  background-color: #a9e062 !important;
}
.pending {
  background-color: #ffeb94 !important;
}
.reports-table {
  max-height: 70vh;
}
.reports-table th,
.reports-table td {
  height: 30px !important;
  width: 80px !important;
  text-align: center;
}

.reports-table .checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.reports-table .row-selected {
  background-color: #a9e06267 !important;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
