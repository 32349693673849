html {
  height: 100%;
}

body {
  height: 100%;
  /* overflow: hidden; */
  margin: 0px;
  display: flex;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

.container {
  height: 100%;
  display: flex;
}

.main {
  flex: 4;
}
.btn {
  cursor: pointer;
}
.action-btn {
  background-color: #a9e062;
}
.action-btn:hover {
  background-color: #8ec24b;
}
.danger-btn {
  background-color: #d74c4c;
}
.danger-btn:hover {
  background-color: #ac3c3c;
}
.warning-btn {
  background-color: #fff3cd;
  border-color: #bd9c39;
}
.warning-btn:hover {
  background-color: #ffe38f;
}
.secondary-btn {
  background-color: #ccc;
}

.secondary-btn:hover {
  background-color: #dddddd;
}

.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
}

.layoutRoot {
  display: flex;
  background: #eee;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.absoluteLayout {
  height: 600px;
  position: relative;
  justify-content: center;
  align-items: center;
}
.scaledLayout {
  width: 125%;
  left: -12.5%;
  transform: scale(0.75);
  margin-top: -7.5%;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ccc;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px;
}
.box .text {
  text-align: center;
}

.hover-handles .react-resizable-handle {
  display: none;
}
.hover-handles:hover .react-resizable-handle {
  display: block;
}
.absolutely-positioned {
  position: absolute !important;
}
.left-aligned {
  left: 0;
}
.right-aligned {
  right: 0;
}
.top-aligned {
  top: 0;
}
.bottom-aligned {
  bottom: 0;
}

.custom-box {
  overflow: visible;
}
.custom-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #1153aa;
  opacity: 0.75;
  border-radius: 4px;
}
.custom-handle-sw {
  bottom: -4px;
  left: -4px;
  cursor: sw-resize;
}
.custom-handle-se {
  bottom: -4px;
  right: -4px;
  cursor: se-resize;
}
.custom-handle-nw {
  top: -4px;
  left: -4px;
  cursor: nw-resize;
}
.custom-handle-ne {
  top: -4px;
  right: -4px;
  cursor: ne-resize;
}
.custom-handle-w,
.custom-handle-e {
  top: 50%;
  margin-top: -4px;
  cursor: ew-resize;
}
.custom-handle-w {
  left: -4px;
}
.custom-handle-e {
  right: -4px;
}
.custom-handle-n,
.custom-handle-s {
  left: 50%;
  margin-left: -4px;
  cursor: ns-resize;
}
.custom-handle-n {
  top: -4px;
}
.custom-handle-s {
  bottom: -4px;
}
.custom-resize-handle-component {
  background-color: red;
}

.Toastify__toast--dark {
  background: #454444 !important;
}

.Toastify__progress-bar--dark {
  background: #ff0404 !important;
}

.filter-checkbox {
  border-radius: 50%;
  border: 1px solid black;
  background-color: #61ef61;
  width: 10px;
  height: 10px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.filter-exclude {
  background-color: red;
}

.filter-ignore {
  background-color: rgb(238, 238, 238);
  border: 1px solid black;
}
